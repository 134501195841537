const config = {
  rootProjectId: "root",
  uiBucketName: "riotestv2.c.retter.io",
  appUrl: "https://api.riotestv2.retter.io/",
  cosUrl: "api.riotestv2.retter.io",
  version: "2.1.24",
  captchaKey: "6Lc4ajkpAAAAAFWZTDh6AEN6CqacPVy5dXF5PuWs",
  stage: "PROD"
}
export default config

